import mhHead from "../../../../static/images/icons/mh_head.png";
import mhTelescope from "../../../../static/images/icons/mh_telescope.png";

export default [
	{
		name: "Mental Health Professional",
		details:
			"Engage with licensed wellness professionals on an appointment-based schedule.",
		img: mhHead,
		url: "/booking/mind/intake",
		value: Buffer.from(process.env.GATSBY_MENTAL_HEALTH_PROGRAM_CODE),
	},
	{
		name: "Nutritionist",
		details: "Achieve your goals with personalized dietary advice.",
		img: mhTelescope,
		url: "/booking/nutritionist",
		value: Buffer.from(process.env.GATSBY_MENTAL_HEALTH_PROGRAM_CODE),
	},
];
